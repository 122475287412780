import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IntercitySettingsService {
  private intercitySettingsSubject = new BehaviorSubject<any>(null);
  intercitySettings$ = this.intercitySettingsSubject.asObservable();

  /**
   * Updates the intercity settings with the provided new settings.
   *
   * @param {any} newSettings - The new settings to update the intercity settings with.
   * @return {void} This function does not return anything.
   */
  updateintercitySettings(newSettings: any) {
    this.intercitySettingsSubject.next(newSettings);
  }
}
